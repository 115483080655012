import {createBrowserRouter, Navigate} from "react-router-dom";
import App from "../App/App";
import Footer from "../Footer/Footer";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/en",
        element:<App/>,
    },
]);

export default Router;
