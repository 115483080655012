import './About.css';
import my_photo from '../../../images/photo.jpg';

function About() {
  return (
    <section className="about">
        <div className="about__description">
            <h3 className="about__title">Hi!</h3>
            <h3 className="about__title">My name is Tim, i'm Golang Developer</h3>
            <p className="about__text">More facts about me:</p>
            <div className="about__facts">
                <div className="about__fact">
                    <span className="about__list-emoji">🎓</span>
                    <span className="about__list-fact"> Received a specialized education at a university</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">🎯</span>
                    <span className="about__list-fact"> I can explain complex things in simple language</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">✨</span>
                    <span className="about__list-fact"> I think programming is interesting</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">💻</span>
                    <span className="about__list-fact"> I have been working in IT for over 5 years</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">🌐&nbsp;</span>
                    <span className="about__list-fact">I have experience in Web and mobile development</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">📚&nbsp;</span>
                    <span className="about__list-fact">Constantly learning new technologies</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">⚙️</span>
                    <span className="about__list-fact"> I turn ideas into projects</span>
                </div>
            </div>
        </div>
        <img src={my_photo} alt="Мое фото" className="about__image"/>
    </section>
  );
}

export default About;
