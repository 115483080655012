import './Projects.css';
import hexagame from '../../../images/hexagame.jpg';
import youtube from '../../../images/youtube.jpg';
import spo44 from '../../../images/spo44.jpg';
import ksmolnia from '../../../images/molnia.jpg';

function Projects() {
  return (
    <section className="projects">

            <div className="projects__item">
                <a className="projects__link" href="https://www.youtube.com/@S0lved" target="_blank" rel="noreferrer">
                    <div className="projects__item-sticker">
                        Project: YouTube channel <br/>
                        Educational content <br/>
                        Developing websites and mobile apps <br/>
                        Different languages and technologies <br/>
                    </div>
                    <div className="projects__item-view">
                        <img loading="lazy" className="projects__item-image" src={youtube} alt=""/>
                        <h3 className="projects__item-heading">YouTube</h3>
                    </div>
                        <div className="projects__item-description">
                    </div>
                </a>
            </div>

        <div className="projects__item">
            <a className="projects__link" href="#"  rel="noreferrer">
                <div className="projects__item-sticker">
                    Project: Woodworker 44 <br/>
                    Wooden house developer <br/>
                    Automatic house cost calculations <br/>
                    depends on building materials cost
                </div>
                <div className="projects__item-view">
                    <img loading="lazy" className="projects__item-image" src={spo44} alt=""/>
                    <h3 className="projects__item-heading">Spo44.ru</h3>
                </div>
                <div className="projects__item-description">

                </div>
            </a>
        </div>
        <div className="projects__item">
            <a className="projects__link" href="#"  rel="noreferrer">
                <div className="projects__item-sticker">
                    Project: KS Molnia <br/>
                    Delivery service website <br/>
                    Role model for workers <br/>
                    Closed for audience
                </div>
                <div className="projects__item-view">
                    <img loading="lazy" className="projects__item-image" src={ksmolnia} alt=""/>
                    <h3 className="projects__item-heading">Ksmolnia.ru</h3>
                </div>
                <div className="projects__item-description">

                </div>
            </a>
        </div>
        <div className="projects__item">
            <a className="projects__link" href="https://hexagame.ru/" target="_blank" rel="noreferrer">
                <div className="projects__item-sticker">
                    Project: Hexagame <br/>
                    Game - logical maze, written on Flutter <br/>
                    Single Page Application, works at: <br/>
                    Android, IOS, Web, MasOS, Windows, Linux <br/>
                    Available at Google Play and at hexagame.ru
                </div>
                <div className="projects__item-view">
                    <img loading="lazy" className="projects__item-image" src={hexagame} alt=""/>
                    <h3 className="projects__item-heading">Hexagame</h3>
                </div>
                <div className="projects__item-description">

                </div>
            </a>
        </div>
        <div className="projects__item">
            <a href="https://t.me/artyom_chernov" target="_blank" rel="noreferrer">
                <div className="projects__item-view">
                    <span className="projects__item-image">
                        <svg width="80px" height="80px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 12H20M12 4V20" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                </div>
            </a>
        </div>
    </section>
  );
}

export default Projects;
