import './App.css';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import Navigation from "../../ru/Navigation/Navigation";
import EnNavigation from "../../en/Navigation/Navigation";
import {useState} from "react";
import {useSearchParams} from 'react-router-dom';


function App() {

    const [activeTab, setActiveTab] = useState("about");
    const [searchParams, setSearchParams] = useSearchParams();
    let en = searchParams.get("en")

    function handleClick(e) {
        e.preventDefault()
        let li = e.target.closest("li")
        let selectedType = li.getAttribute('type')
        let tabs = document.querySelectorAll('.navigation__tabs > li')
        tabs.forEach.call(tabs, function(el) {
            el.classList.remove("active");
        });
        li.classList.add("active")
        setActiveTab(selectedType)
    }

  return (

      <div className="App">
          {  !en ?
              <Navigation handleClick={e => handleClick(e)}/> :
              <EnNavigation handleClick={e => handleClick(e)}/>
          }
          <Main activeTab={activeTab}/>
    </div>
  );
}

export default App;
