import { useCallback } from 'react';
import ReactFlow, {
    Controls,
    useNodesState,
    useEdgesState,
    addEdge, MarkerType, Position,
} from 'reactflow';

import 'reactflow/dist/style.css';
import './Skills.css';

const initialNodes = [
    // MAIN NODE
    {
        id: '1',
        position: { x: 0, y: 200 },
        style: {
            background: '#a3a8a8',
            color: 'white',
            fontSize: '20px',
            width: '180px',
        },
        data: {
            label: (
                <>
                    <hr/>
                    My Skills 👨‍💻 <br/>Mindmap
                    <hr/>
                </>
            ),
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Right,
    },
    // MAIN-FRONTEND NODE
    {
        id: '2',
        position: { x: 250, y: 47 },
        style: {
            background: 'rgba(163,168,168,0.71)',
            color: 'white',
            fontSize: '20px',
            width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    🎨️ Frontend
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // MAIN-BACKEND NODE
    {
        id: '3',
        position: { x: 580, y: 234 },
        style: {
            background: 'rgba(163,168,168,0.71)',
            color: 'white',
            fontSize: '20px',
            width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    🛠️ Backend
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // MAIN-TOOLS NODE
    {
        id: '4',
        position: { x: 250, y: 445 },
        style: {
            background: 'rgba(163,168,168,0.71)',
            color: 'white',
            fontSize: '20px',
            width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    🧰 Tools
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // FRONTEND-react NODE
    {
        id: '5',
        position: { x: 450, y: 0 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '100px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    React
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // FRONTEND-react-modx NODE
    {
        id: '6',
        position: { x: 600, y: 0 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '100px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Modx
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // FRONTEND-css NODE
    {
        id: '7',
        position: { x: 450, y: 50 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '100px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Css
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // FRONTEND-css-flexbox NODE
    {
        id: '8',
        position: { x: 600, y: 50 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '100px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Flexbox
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // FRONTEND-js NODE
    {
        id: '9',
        position: { x: 450, y: 100 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    JS + jQUERY
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // FRONTEND-bootstrap NODE
    {
        id: '10',
        position: { x: 450, y: 150 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    BOOTSTRAP
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-git NODE
    {
        id: '25',
        position: { x: 450, y: 300 },
        style: {
            background: 'rgba(86,7,192,0.15)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    GIT
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-docker NODE
    {
        id: '11',
        position: { x: 450, y: 350 },
        style: {
            background: 'rgba(86,7,192,0.15)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Docker
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-elastic NODE
    {
        id: '12',
        position: { x: 450, y: 395 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Elastic <br/>
                    search
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-swagger NODE
    {
        id: '13',
        position: { x: 450, y: 480 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Swagger
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-linux NODE
    {
        id: '14',
        position: { x: 450, y: 525 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Linux
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-db NODE
    {
        id: '15',
        position: { x: 580, y: 448 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '50px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    DB
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-db-SQL NODE
    {
        id: '16',
        position: { x: 645, y: 370 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    *SQL*
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-db-mongo NODE
    {
        id: '17',
        position: { x: 645, y: 420 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    MongoDB
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-db-clickhouse NODE
    {
        id: '18',
        position: { x: 645, y: 468 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Clickhouse
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // TOOLS-db-prometheus NODE
    {
        id: '19',
        position: { x: 645, y: 515 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Prometheus
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-c++ NODE
    {
        id: '20',
        position: { x: 800, y: 94 },
        style: {
            background: 'rgba(163,168,168,0.71)',
            color: 'white',
            fontSize: '20px',
            width: '130px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    C++
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-java NODE
    {
        id: '21',
        position: { x: 800, y: 164 },
        style: {
            background: 'rgba(163,168,168,0.71)',
            color: 'white',
            fontSize: '20px',
            width: '130px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Java
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-c# NODE
    {
        id: '22',
        position: { x: 800, y: 234 },
        style: {
            background: 'rgba(163,168,168,0.71)',
            color: 'white',
            fontSize: '20px',
            width: '130px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    C#
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-python NODE
    {
        id: '23',
        position: { x: 800, y: 304 },
        style: {
            background: 'rgba(163,168,168,0.71)',
            color: 'white',
            fontSize: '20px',
            width: '130px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Python
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-golang NODE
    {
        id: '24',
        position: { x: 800, y: 374 },
        style: {
            background: 'rgba(86,7,192,0.38)',
            color: 'white',
            fontSize: '20px',
            width: '130px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    GoLang
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-c++-mpi NODE
    {
        id: '26',
        position: { x: 960, y: 70 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    MPI
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-c++-omp NODE
    {
        id: '27',
        position: { x: 960, y: 120 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    OpenMP
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-java-spring NODE
    {
        id: '28',
        position: { x: 960, y: 167 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Spring Boot
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-c#-signalr NODE
    {
        id: '29',
        position: { x: 960, y: 237 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    SignalR
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-python-flask NODE
    {
        id: '30',
        position: { x: 960, y: 287 },
        style: {
            background: 'rgba(163,168,168,0.49)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Flask
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
    // BACKEND-python-django NODE
    {
        id: '31',
        position: { x: 960, y: 337 },
        style: {
            background: 'rgba(86,7,192,0.15)',
            color: 'white',
            fontSize: '14px',
            width: '120px',
            // width: '180px',
            letterSpacing: '1.5px',
        },
        data: {
            label: (
                <>
                    Django
                </>
            )
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
    },
];

const initialEdges = [
    // MAIN NODE CONNECTIONS
    {
        id: 'e1-2',
        source: '1',
        target: '2',
        animated: true,
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e1-3',
        source: '1',
        target: '3',
        animated: true,
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e1-4',
        source: '1',
        target: '4',
        animated: true,
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // FRONTEND NODE CONNECTIONS
    {
        id: 'e2-4',
        source: '2',
        target: '5',
        // animated: true,
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e2-7',
        source: '2',
        target: '7',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e2-9',
        source: '2',
        target: '9',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e2-10',
        source: '2',
        target: '10',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // FRONTEND-react NODE CONNECTIONS
    {
        id: 'e5-6',
        source: '5',
        target: '6',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // FRONTEND-css NODE CONNECTIONS
    {
        id: 'e7-8',
        source: '7',
        target: '8',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // TOOLS NODE CONNECTIONS
    {
        id: 'e4-11',
        source: '4',
        target: '11',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e4-12',
        source: '4',
        target: '12',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e4-13',
        source: '4',
        target: '13',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e4-14',
        source: '4',
        target: '14',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e4-15',
        source: '4',
        target: '15',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e4-25',
        source: '4',
        target: '25',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // TOOLS NODE-db CONNECTIONS
    {
        id: 'e15-16',
        source: '15',
        target: '16',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e15-17',
        source: '15',
        target: '17',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e15-18',
        source: '15',
        target: '18',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e15-19',
        source: '15',
        target: '19',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // BACKEND NODE CONNECTIONS
    {
        id: 'e3-20',
        source: '3',
        target: '20',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e3-21',
        source: '3',
        target: '21',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e3-22',
        source: '3',
        target: '22',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e3-23',
        source: '3',
        target: '23',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e3-24',
        source: '3',
        target: '24',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // BACKEND-c++ NODE CONNECTIONS
    {
        id: 'e20-26',
        source: '20',
        target: '26',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e20-27',
        source: '20',
        target: '27',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // BACKEND-java NODE CONNECTIONS
    {
        id: 'e21-28',
        source: '21',
        target: '28',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // BACKEND-c# NODE CONNECTIONS
    {
        id: 'e22-29',
        source: '22',
        target: '29',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    // BACKEND-python NODE CONNECTIONS
    {
        id: 'e23-30',
        source: '23',
        target: '30',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
    {
        id: 'e23-31',
        source: '23',
        target: '31',
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    },
];

function Skills() {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    return (
        <section className="skills">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                className="skills__flow"
            >
                <Controls />
            </ReactFlow>
        </section>
    );
}

export default Skills;