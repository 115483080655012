import './About.css';
import my_photo from '../../../images/photo.jpg';

function About() {
  return (
    <section className="about">
        <div className="about__description">
            <h3 className="about__title">Привет!</h3>
            <h3 className="about__title">Меня зовут Артём, я Golang разработчик</h3>
            <p className="about__text">Чуть подробнее:</p>
            <div className="about__facts">
                <div className="about__fact">
                    <span className="about__list-emoji">🎓</span>
                    <span className="about__list-fact"> Получил профильное образование в вузе</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">🎯</span>
                    <span className="about__list-fact"> Могу объяснить простым языком сложные вещи</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">✨</span>
                    <span className="about__list-fact"> Считаю, что программирование - это интересно</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">💻</span>
                    <span className="about__list-fact"> Занимаюсь IT более 5 лет</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">🌐&nbsp;</span>
                    <span className="about__list-fact">Хорошо владею Web и мобильной разработкой</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">📚&nbsp;</span>
                    <span className="about__list-fact">Постоянно изучаю новые технологии</span>
                </div>
                <div className="about__fact">
                    <span className="about__list-emoji">⚙️</span>
                    <span className="about__list-fact"> Воплощаю идеи в проекты</span>
                </div>
            </div>
        </div>
        <img src={my_photo} alt="Мое фото" className="about__image"/>
    </section>
  );
}

export default About;
