import './Main.css';
import Footer from "../Footer/Footer";
import About from "../../ru/About/About";
import EnAbout from "../../en/About/About";
import Projects from "../../ru/Projects/Projects";
import EnProjects from "../../en/Projects/Projects";
import Skills from "../Skills/Skills";
import {useSearchParams} from "react-router-dom";

const Main = ({activeTab}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	let en = searchParams.get("en")
	return (
		<main className="main">
			{ activeTab === "about" ?
				!en ?
					<About/> :
					<EnAbout/>
				: ""}
			{ activeTab === "projects" ?
				!en ?
					<Projects/> :
					<EnProjects/>
				: ""}
			{ activeTab === "skills" ? <Skills/> : ""}
			<Footer/>
		</main>
	);
};

export default Main;
